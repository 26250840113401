import React  from "react"

import Layout from "../../components/layout"
import ReactHtmlParser from "react-html-parser"

import Nav from "../../components/nav"
import SEO from "../../components/seo"

import styled from "styled-components"

const Section = styled.div`
  margin: 0 2rem;
  padding-top:50px;
`

const Grid = styled.div`
  display: grid;
  grid-auto-columns: 150px 1fr;
  .date {
    grid-column: 1;
  }
  .text-block {
    grid-column: 2;
    padding-right: 7rem;
    &.space {
      height: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    grid-auto-columns: 1fr;
    .date {
      grid-column: 1 / -1;
    }
    .text-block {
      grid-column: 1 / 1;
      padding-right: 2rem;
      &.space {
        height: 30px;
      }
    }
  };
  div {
      font-family: minion-pro-display, serif;
      font-size: 1.2rem;
      line-height: 1.6rem;
  }
`

const Page = ({ pageContext }) => {

  const page = pageContext.page

  return (
    <Layout>
      <SEO title={page.title} />
      <Nav />
      <Section>
        <main>
        {
          page.info.content.map((cc, index) => {
            var spacer = cc.spacer ? `text-block space` : `text-block`;
            const styleObj = {
              letterSpacing: cc.letterspacing
          }
            return <Grid key={index}>
              <div className="date">{cc.date}</div> 
              <div style={styleObj} className={spacer}> {ReactHtmlParser( cc.art )}</div>
            </Grid>
          })
        }
        </main> 
      </Section>
    </Layout>
  )
}

export default Page